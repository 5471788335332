body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .about-us {
    padding: 60px 20px;
  
    .container {
      max-width: 1200px;
      margin: 0 auto;
      text-align: center;
    }
  
    h1 {
      font-size: 3em;
      color: #bb5f27;
      margin-bottom: 20px;
    }
  
    .intro {
      font-size: 1.2em;
      color: #777;
      margin-bottom: 40px;
    }
  
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  
      .text {
        flex: 2;
        padding: 0 20px;
  
        h2 {
          font-size: 2em;
          color: #bb5f27;
          margin-bottom: 15px;
        }
  
        p {
          font-size: 1.1em;
          color: white;
          line-height: 1.6;
          margin-bottom: 20px;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .content {
      flex-direction: column;
  
      .image {
        margin-bottom: 20px;
      }
    }
  
    h1 {
      font-size: 2.5em;
    }
  
    .intro {
      font-size: 1.1em;
    }
  
    .text {
      h2 {
        font-size: 1.8em;
      }
  
      p {
        font-size: 1em;
      }
    }
  }
  