.form {
    max-width: 500px;
    width: 90%;
    margin: 1rem auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #DAD7CD;
    border-radius: 2rem;
    filter: drop-shadow(0 0 50px black);
    gap: 1.5rem;
  
    .heading {
      .heading-text {
        font-size: 2rem;
        color: #333;
      }
    }
  
    .input-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
  
      .input-label {
        font-size: 1rem;
        color: #666;
      }
  
      input {
        padding: 0.75rem;
        border-radius: 1rem;
        border: 1px solid #A3B18A;
        font-size: 1rem;
        width: 100%;
        box-sizing: border-box;
      }
    }
  
    .btn {
      .submit-btn {
        padding: 0.75rem 2rem;
        background-color: #A3B18A;
        color: #fff;
        border: none;
        border-radius: 3rem;
        font-size: 1rem;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #8c9a6d;
          cursor: pointer;
        }
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .form {
      padding: 1.5rem;
      gap: 1rem;
  
      .heading {
        .heading-text {
          font-size: 1.75rem;
        }
      }
  
      .input-group {
        .input-label {
          font-size: 0.875rem;
        }
  
        input {
          font-size: 0.875rem;
          padding: 0.5rem;
        }
      }
  
      .btn {
        .submit-btn {
          font-size: 0.875rem;
          padding: 0.5rem 1.5rem;
        }
      }
    }
  }
  
  @media screen and (max-width: 480px) {
    .form {
      padding: 1rem;
      gap: 0.75rem;
  
      .heading {
        .heading-text {
          font-size: 1.5rem;
        }
      }
  
      .input-group {
        .input-label {
          font-size: 0.75rem;
        }
  
        input {
          font-size: 0.75rem;
          padding: 0.5rem;
        }
      }
  
      .btn {
        .submit-btn {
          font-size: 0.75rem;
          padding: 0.5rem 1rem;
        }
      }
    }
  }
  