/* App.css */

html, body {
  height: 100%;
  margin: 0;
  background-color: #212529;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

main {
  flex: 1; /* Takes up remaining space */
}

.footer {
  background-color: #333;
  color: white;
  padding: 0;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(150, 54, 54, 0.923); /* Optional: Add a shadow for visibility */
}
