.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  
    h1 {
      font-size: 2.5rem;
      color: #bb5f27;
      margin-bottom: 1rem;
    }
  
    p {
      font-size: 1.2rem;
      color: white;
      margin-bottom: 2rem;
    }
  
    .contact-links {
      display: flex;
      justify-content: space-around;
  
      a {
        display: flex;
        align-items: center;
        color: white;
        text-decoration: none;
        font-size: 1.1rem;
        transition: color 0.3s ease;
  
        &:hover {
          color: #0077b5;
        }
  
        .icon {
          margin-right: 0.5rem;
          font-size: 1.8rem;
        }
      }
    }
  
    @media (max-width: 768px) {
      padding: 1.5rem;
  
      h1 {
        font-size: 2rem;
      }
  
      p {
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }
  
      .contact-links {
        flex-direction: column;
  
        a {
          margin-bottom: 1rem;
          font-size: 1rem;
  
          .icon {
            font-size: 1.5rem;
          }
        }
      }
    }
  
    @media (max-width: 480px) {
      padding: 1rem;
  
      h1 {
        font-size: 1.8rem;
      }
  
      p {
        font-size: 0.9rem;
        margin-bottom: 1rem;
      }
  
      .contact-links {
        a {
          margin-bottom: 0.8rem;
          font-size: 0.9rem;
  
          .icon {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
  