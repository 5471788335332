/* Homepage.scss */

.homepage {
  display: flex;
  justify-content: center;
  padding: 2rem;
  flex: 1; /* Ensure the homepage content takes up the remaining space */

  .city-list {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: space-evenly;
    align-items: center;
  }
  #jaipur {
    background-image: url("../../images/jaipur.jpg");
  }
  #delhi {
    background-image: url("../../images/delhi.jpg");
  }
  #mumbai {
    background-image: url("../../images/mumbai.jpg");
  }
  #banglore {
    background-image: url("../../images/banglore.jpg");
  }
  #pune {
    background-image: url("../../images/pune.jpg");
  }
  #kolkata {
    background-image: url("../../images/kolkata.jpg");
  }
  #gurgaon {
    background-image: url("../../images/gurgaon.jpg");
  }
  #chandigarh {
    background-image: url("../../images/chandigarh.jpg");
  }

  .city-card {
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
    width: 300px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    filter: drop-shadow(0 0 25px black);
    transition: transform 0.3s ease;

    &:hover {
      filter: drop-shadow(1px 1px 2.5px rgba(255, 255, 255, 0.532));
      transform: scale(1.03);
    }

    .city-button {
      display: inline-block;
      outline: none;
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      font-size: 14px;
      line-height: 1;
      border-radius: 500px;
      transition-property: background-color, border-color, color, box-shadow,
        filter;
      transition-duration: 0.3s;
      border: 1px solid transparent;
      letter-spacing: 2px;
      min-width: 120px;
      text-transform: uppercase;
      white-space: normal;
      font-weight: 700;
      text-align: center;
      padding: 16px 14px 18px;
      color: #fcfeff;
      box-shadow: inset 0 0 0 1px #b0b3b6cf;
      margin-top: 10rem;
      height: 48px;
      &:hover {
        color: #fff;
        background: rgba(0, 0, 0, 0.2);
        transform: translate3d(-2px,-1px,0);
      }
    }
  }
}
