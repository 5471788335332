.privacy-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 10px;
    color: white;
  
    .privacy-policy-title {
      font-size: 2.5em;
      margin-bottom: 20px;
      text-align: center;
      color: white;
    }
  
    .privacy-policy-date {
      text-align: center;
      font-style: italic;
      color: white;
    }
  
    .privacy-policy-section {
      margin-bottom: 30px;
  
      h2 {
        font-size: 1.75em;
        margin-bottom: 10px;
        color: #bb5f27;
      }
  
      p {
        font-size: 1em;
        line-height: 1.6;
        margin-bottom: 15px;
      }
  
      ul {
        padding-left: 20px;
        list-style-type: disc;
  
        li {
          margin-bottom: 10px;
        }
      }
    }
  
    // Media Queries for Responsiveness
  
    @media (max-width: 1200px) {
      .privacy-policy-title {
        font-size: 2.2em;
      }
  
      .privacy-policy-section h2 {
        font-size: 1.6em;
      }
    }
  
    @media (max-width: 992px) {
      padding: 15px;
  
      .privacy-policy-title {
        font-size: 2em;
      }
  
      .privacy-policy-date {
        font-size: 0.9em;
      }
  
      .privacy-policy-section h2 {
        font-size: 1.4em;
      }
  
      p {
        font-size: 0.9em;
      }
  
      ul {
        padding-left: 15px;
      }
    }
  
    @media (max-width: 768px) {
      padding: 10px;
  
      .privacy-policy-title {
        font-size: 1.8em;
      }
  
      .privacy-policy-date {
        font-size: 0.8em;
      }
  
      .privacy-policy-section h2 {
        font-size: 1.2em;
      }
  
      p {
        font-size: 0.85em;
      }
  
      ul {
        padding-left: 10px;
      }
    }
  
    @media (max-width: 576px) {
      padding: 5px;
  
      .privacy-policy-title {
        font-size: 1.6em;
      }
  
      .privacy-policy-date {
        font-size: 0.7em;
      }
  
      .privacy-policy-section h2 {
        font-size: 1em;
      }
  
      p {
        font-size: 0.8em;
      }
  
      ul {
        padding-left: 5px;
      }
    }
  }
  