@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #343A40;
  //background-color: rgba(5, 67, 81, 0.404);
  z-index: 1000;
  width: auto;
  height: 7vh;

  .logo {
    font-size: 1.5rem;
    font-weight: bold;

    img{
      width: 100px;
      height: 80px;
      filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.963))  /* Increased blur radius and opacity */
      drop-shadow(15px 0 35px rgba(255, 255, 255, 0.7))  /* Increased horizontal offset, blur radius, and opacity */
      drop-shadow(0 0 50px rgba(0, 128, 255, 0.7));      /* Increased blur radius and opacity */
    }
  }

  .search-filter-container {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 0 1rem;

    .search-bar {
      width: 100%;
      max-width: 400px;

      input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
  }

  .nav-links {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    .nav-items {
      display: flex;
      gap: 1rem;

      .link {
        // font-family: "Quicksand", sans-serif;
        //font-family: "Dancing Script", cursive;
        // font-family: "Satisfy", system-ui;
        // font-weight: 400;
        // font-style: normal;
        // font-optical-sizing: auto;
        // font-size: 1.3rem;
        text-decoration: none;
        color: white;
        position: relative;
        padding-bottom: 0.5rem;
        margin: 0 1rem;
        transition: color 0.3s ease;

        &:hover {
          color: #A2D2FF; /* Color on hover */
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 2px; /* Thickness of the border */
          background-color: #A2D2FF; /* Color of the border */
          transition: width 0.3s ease; /* Animation for the border */
        }

        &:hover::after {
          width: 100%;
        }
      }
      .closeNavbar{
        display: none;

        .btn{
          font-size: 1.5rem;
          align-items: center;
          justify-content: center;
          margin-top: 0;
          padding: 0;
          cursor: pointer;
          color:white;

          &:hover{
            color: #007bff;
          }
        }
      }
    }
  }

  .toggleNavbar {
    display: none; /* Hidden by default */
    align-items: center; /* Center the button vertically */
    margin-left: 1rem;

    .btn {
      font-size: 1.5rem;
      cursor: pointer;
      color: white;

      &:hover {
        color: #007bff;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .nav-links{
      position: absolute;
      top: -100%;
      left: 0;
      right: 0;
      background: #fff;
      transition: all 0.3s ease;
      z-index: 1000;

      &.activeNavbar{
        top: 60px; /* Adjust based on the header height */
      }

      .nav-items{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: rgba(5, 67, 81, 0.404);
        width: 100%;
        padding: 1rem 0;
        height: 20px;
        border-bottom: 2px solid black;
        border-top: 2px solid black;
        transition: 2s ease-in-out;

        .link{
          margin: 1rem 0;
        }
        .closeNavbar{
          display: flex;
          transition: all 0.3s ease;
        }
      }
    }

    .toggleNavbar{
      display: flex;
    }
  }
  @media screen and (min-width:481px) and (max-width:768px){
    .header{
      width: auto;
    }
  }
}